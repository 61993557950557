import styled from 'styled-components';

export const Container = styled.div<{ minHeight?: number }>`
  width: 100%;
  height: calc(100vh - 200px);
  max-height: 900px;
  position: relative;

  /* for homepage takeover, so title and button do not get cut off */
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : '')}
`;

export const Content = styled.div`
  &:before {
    content: '';
    position: absolute;
    height: 300px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85));
    opacity: 0.6;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageBox = styled.img<{ active: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s;
  transition-delay: 2s;

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    transition-delay: 0s;
  `}
`;
